import api from "@/base/utils/request";

/**
 * 文章导入
 * @param data
 */
export const getWxPublicArticle = data => {
  return api({
    url: '/admin/portal/article/getWxPublicArticle',
    method: 'post',
    data
  })
}
