<template>
  <el-dialog
    title="插入腾讯文档链接"
    class="tencent-file-link dialog-vertical"
    :visible="value"
    width="450px"
    @close="close"
    append-to-body
  >
    <el-form
      ref="linkForm"
      :model="form"
      :rules="rules"
      size="medium"
      label-width="80px"
      label-position="top"
      @submit.native.prevent
    >
      <el-form-item label="文档链接" prop="link">
        <el-input
          v-if="value"
          v-focus
          style="width: 100%; max-width: 100%; height: unset"
          :rows="6"
          v-model="form.link"
          type="textarea"
          placeholder="如：https://docs.qq.com/form/page/DRWhRYkplVVlFVmVq"
        ></el-input>
      </el-form-item>
      <el-form-item label="链接文字">
        <el-input v-model="form.title" placeholder="选填"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="loading"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'TencentFileLink',
  props: {
    /** v-model */
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        link: '',
        title: '',
      },
      rules: {
        link: [
          { required: true, message: '请输入腾讯文档链接', trigger: 'blur' },
          {
            // 验证是否腾讯文档域名
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入腾讯文档链接'))
              }
              if (!/^https:\/\/docs\.qq\.com/.test(value)) {
                callback(new Error('请输入正确的腾讯文档链接'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    close() {
      this.form.link = ''
      this.$refs.linkForm.resetFields()
      this.$refs.linkForm.clearValidate()
      this.$emit('input', false)
    },
    confirm() {
      this.$refs.linkForm.validate((valid) => {
        if (valid) {
          window.tinymce.execCommand(
            'mceReplaceContent',
            false,
            `<a target="_blank" href="${this.form.link}">${
              this.form.title || this.form.link
            }</a>`
          )
          this.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
