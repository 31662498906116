<template>
  <div class="add-subjects">
    <el-button
      class="subject-add-btn"
      type="primary"
      size="small"
      @click="addLine"
      >添加</el-button
    >
    <!-- <p>提示：输入多个金额选项，方便用户快捷捐款，<el-button type="text" @click="picker">效果预览</el-button></p> -->
    <div class="subject-list" v-show="myValue.length !== 0">
      <draggable v-model="myValue" animation="500" draggable=".draggable">
        <div
          class="single-subject draggable"
          v-for="(subject, index) in myValue"
          :key="index"
        >
          <div class="item flex">
            <!-- 快捷捐款 -->
            <div class="item-input">
              <img src="../../assets/operation.png" alt="" />
              <p>捐款金额：</p>
              <el-input
                type="number"
                v-model="myValue[index]"
                placeholder="请输入"
                :disabled="inputEdit[index]"
                @blur="inputBlur(index)"
              ></el-input>
              <el-button type="text">
                <i
                  class="el-icon-edit"
                  @click="myEdit(index)"
                  :style="{ color: inputEdit[index] ? '#000' : 'orange' }"
                ></i>
              </el-button>
              <el-button type="text">
                <i class="el-icon-delete" @click="delet(index)"></i>
              </el-button>
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  model: {
    prop: "value",
    event: "handleValue",
  },
  props: {
    // 快捷捐款数组
    value: {
      type: Array,
    },
  },
  components: { draggable },
  data() {
    return {
      loading: true,
      subjects: [],
      // 启用或者禁用表单输入，跟传入进来的myValue 同步长度
      inputEdit: [],
    };
  },

  computed: {
    // 快捷捐款数组
    myValue: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, 123);
        this.$emit("handleValue", val);
      },
    },
  },
  created() {
    console.log(this.myValue);
  },
  methods: {
    // 表单取消焦点
    inputBlur(i) {
      this.$set(this.inputEdit, i, true);
    },
    // 移除
    delet(i) {
      this.$msgbox
        .confirm("确定是否删除", "提示", {
          type: "error",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then((res) => {
          this.myValue.splice(i, 1);
        })
        .catch((err) => {});
    },
    // 添加
    addLine() {
      console.log(this.myValue);
      if (this.myValue && this.myValue.length === 4) {
        this.$message.error("最多添加4个");
        return;
      }
      this.myValue.push("");
      this.inputEdit.push(false);
      this.$emit("input", this.myValue);
    },
    picker() {
      console.log(111);
    },
    // 编辑&&保存
    myEdit(i) {
      // 直接修改数组不能响应式更新
      this.$set(this.inputEdit, i, !this.inputEdit[i]);
      if (this.inputEdit[i]) {
        console.log(this.myValue);
      }
      console.log(this.inputEdit[i], i);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-subjects {
  width: 400px;
  .subject-list {
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    padding: 10px;
    .single-subject {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        background-color: #fff;

        padding: 10px;

        margin: 5px 0;
        // background-color: #f4f3f4;
        border-radius: 5px;
        .item-input {
          display: flex;
          justify-content: center;
          align-items: center;
          .el-input {
            margin-right: 10px;
          }
          p {
            width: 130px;
            margin-left: 10px;
          }
        }
      }
    }

    .subject-del-btn {
      width: 30px;
      text-align: right;
      cursor: pointer;
    }
  }

  .subject-add-btn {
    margin-top: 10px;
  }
}

.el-icon-delete {
  color: #000;
}
.el-icon-delete:hover {
  color: #ff4949;
}
</style>
