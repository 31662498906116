// Any plugins you want to use has to be imported
// Detail plugins list see https://www.tinymce.com/docs/plugins/
// Custom builds see https://www.tinymce.com/download/custom-builds/

// autoresize 编辑器大小适应内容高度？？
// imagetools 图片编辑工具
// placeholder 如果 placeholder 配置没生效，可能是某个版本不支持，这里引入第三方实现
// const plugins = ['print preview advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image insertdatetime link tencentfilelink lists media nonbreaking noneditable pagebreak paste save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount formatpainter']
const plugins = ['indent2em importcss quickbars toc textpattern print visualblocks preview advlist anchor autolink autosave code colorpicker colorpicker contextmenu directionality fullscreen hr image insertdatetime link tencentfilelink lists media nonbreaking noneditable pagebreak paste save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount formatpainter']

// const plugins2 = 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs'

export default plugins
