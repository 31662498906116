// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

const toolbar = [
    // styleselect
    // 'fontsizeselect fontselect undo redo searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent blockquote removeformat subscript superscript code codesample formatpainter', 'hr bullist numlist link tencentfilelink imageselector videoselector audioselector charmap anchor insertdatetime table forecolor backcolor fullscreen']
    'undo redo fontsizeselect fontselect formatselect lineheight', 'bold italic underline strikethrough alignleft aligncenter alignright alignjustify indent2em outdent indent blockquote removeformat formatpainter subscript superscript textpattern', 'bullist numlist link tencentfilelink imageselector videoselector audioselector charmap table forecolor backcolor fullscreen']

const toolbar2 = 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
    styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
    table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs'

export default toolbar
