<template>
  <div class="add-project">
    <div class="content" v-loading="loading">
      <div class="project-card">
        <p class="title">基本信息</p>
        <div class="project-content">
          <el-form :model="form" :rules="rules" label-width="120px" @submit.native.prevent>
            <el-form-item label="项目名称：" prop="name">
              <el-input
                style="width: 50%"
                v-model="form.name"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目时间：" prop="time">
              <el-date-picker
                v-model="form.time"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="封面图：" prop="cover">
              <div class="media-preview-list">
                <div class="media-input-item media-preview-item">
                  <SingleMediaWall
                    addText="上传封面"
                    v-model="form.cover"
                    :width="194"
                    :height="135"
                    ratio="375:210"
                    :borderRadius="4"
                  >
                    <p slot="info" class="img-tips" style="width: 194px">
                      建议上传尺寸比例为1125 * 630
                    </p>
                  </SingleMediaWall>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="募捐编号：" prop="donation_number">
              <el-input
                style="width: 50%"
                v-model="form.donation_number"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="发起机构：" prop="start_institution">
              <project-institution
                v-model="form.start_institution"
              ></project-institution>
            </el-form-item>
            <el-form-item label="支持机构：" prop="sponsor_institution">
              <add-input v-model="form.sponsor_institution"></add-input>
            </el-form-item>
            <el-form-item label="目标金额：" prop="target_price">
              <el-input-number
                v-model="form.target_price"
                :min="1"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="快捷捐款：" prop="quick_donation">
              <quick-input v-model="form.quick_donation"></quick-input>
            </el-form-item>
            <el-form-item label="大额捐款提示：" prop="large_donation_msg">
              <el-input
                class="large_msg"
                type="textarea"
                v-model="form.large_donation_msg"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="project-card">
        <p class="title">项目信息</p>
        <div class="project-content">
          <el-form :model="form" :rules="rules" label-width="120px">
            <el-form-item label="项目详情：" prop="content">
              <tinymce
                ref="editor"
                @choose="handleToolBarClick"
                v-model="form.content"
                show-article-import
              ></tinymce>
            </el-form-item>
            <el-form-item label="项目公示：">
              <el-button type="primary" @click="addArticleContent"
                >撰写内容</el-button
              >
              <div v-if="form.donation_publicity" class="publicity">
                <div
                  v-for="(item, i) in form.donation_publicity"
                  class="publicity-item"
                >
                  <div class="title-box">
                    <div class="date">{{ item.create_time }}</div>
                    <div class="edit">
                      <el-button type="text" @click="editContent(item)">
                        <i class="el-icon-edit"></i>
                      </el-button>
                      <el-button type="text">
                        <i
                          class="el-icon-delete"
                          @click="deleteContent(item)"
                        ></i>
                      </el-button>
                    </div>
                  </div>
                  <div class="content">
                    {{ item.content }}
                  </div>
                  <div class="image">
                    <media-wall
                      v-if="item.images"
                      :showMask="false"
                      :limit="
                        form.articleContent.image.length -
                        form.articleContent.image.length
                      "
                      addText="上传图片"
                      :width="150"
                      :upload-type="['image']"
                      :height="100"
                      v-model="item.images"
                    >
                    </media-wall>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="项目答疑：" prop="questions">
              <project-questions v-model="form.questions"></project-questions>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 保存 -->
    <fixed-action-bar :z-index="2">
      <el-button type="primary" @click="projectSave">保存</el-button>
      <el-button @click="close">取消</el-button>
    </fixed-action-bar>
    <!-- 撰写内容弹窗 -->
    <article-dialog
      v-model="isShowPublicity"
      @article="ArticleForm"
      @addPublicity="addPublicity"
      @editPublicity="editPublicity"
      :publicityEditContent="publicityEditContent"
    ></article-dialog>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>
<script>
import { donationSave, donationDetail, addPublicity } from "../../api/project";
import MediaWall from "../../../common/components/MediaWall";
import AddInput from "../../components/project/AddInput";
import FixedActionBar from "@/base/layout/FixedActionBar";
import Tinymce from "@/base/components/Editor/Tinymce";
import MediaSelector from "../../../common/components/MediaSelector";
import ImportArticle from "@/base/components/Editor/ImportArticle";
import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
import ArticleDialog from "../../components/project/ArticleDialog";
import ProjectQuestions from "../../components/project/ProjectQuestions";
// 发起机构组件
import ProjectInstitution from "../../components/project/ProjectInstitution";
import QuickInput from "../../components/project/QuickInput";
import SingleMediaWall from "../../../common/components/SingleMediaWall";
export default {
  components: {
    MediaWall,
    FixedActionBar,
    AddInput,
    Tinymce,
    ImportArticle,
    MediaSelector,
    ImportHtmlCode,
    ArticleDialog,
    ProjectQuestions,
    QuickInput,
    SingleMediaWall,
    ProjectInstitution,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        time: [],
        target_price: 1,
        content: "",
        donation_number: null,
        start_institution: [],
        sponsor_institution: [],
        articleContent: {
          image: [],
          publicity: "",
        },
        questions: [],
        // 快捷捐款项目
        quick_donation: [],
        // 大捐款提示
        large_donation_msg: "",
        // 项目公示列表
        donation_publicity: [],
        // 捐款封面
        cover: "",
      },
      rules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        time: [{ required: true, message: "请选择项目时间", trigger: "blur" }],
        cover: [{ required: true, message: "请选择封面图", trigger: "blur" }],
      },
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
      // 撰写公示内容弹窗
      isShowPublicity: false,
      // 修改公示的内容
      publicityEditContent: {},
    };
  },
  created() {
    if (this.$route.params.id && this.$route.name === "EditProject") {
      this.getProjectDetail(this.$route.params.id);
    }
  },
  methods: {
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case "image":
          this.showImageList = true;
          break;
        case "video":
          this.showVideoList = true;
          break;
        case "audio":
          this.showAudioList = true;
          break;
        case "import":
          this.isShowImportDialog = true;
          break;
        case "html":
          this.isShowHtmlInput = true;
          break;
      }
    },
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        );
      });
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      console.log(this.form);
      this.$set(this.form, "content", e.content);
      this.$refs.editor.$emit("change", e.content);
    },
    ArticleForm(data) {
      console.log(data);
      const d = JSON.parse(JSON.stringify(data));
      this.form.articleContent = d;
    },
    // 保存
    projectSave() {
      if (!this.form.name) {
        this.$message.error("请输入项目名称");
      } else if (!this.form.time) {
        this.$message.error("请选择项目时间");
      } else {
        const data = {
          id: this.$route.params.id,
          name: this.form.name,
          start_time: this.form.time[0] / 1000,
          end_time: this.form.time[1] / 1000,
          donation_number: this.form.donation_number,
          // 发起机构
          start_institution: this.form.start_institution.filter(
            (item) => item.name !== ""
          ),
          // 支持机构
          sponsor_institution: this.form.sponsor_institution.filter(
            (item) => item !== ""
          ),
          target_price: this.form.target_price,
          content: this.form.content,
          publicity: this.form.articleContent.publicity,
          images: this.form.articleContent.image,
          questions: this.form.questions.filter(
            (item) => item.Q !== "" && item.A !== ""
          ),
          // 快捷捐款项目
          quick_donation: this.form.quick_donation.filter(
            (item) => item !== "" && item !== 0
          ),
          // 大捐款提示
          large_donation_msg: this.form.large_donation_msg,
          // 项目公示内容
          donation_publicity: this.form.donation_publicity,
          // 封面
          cover: this.form.cover,
        };
        donationSave({ ...data })
          .then((res) => {
            this.$message.success(res.msg);
            this.$router.push({
              name: "donationProject",
            });
          })
          .catch((err) => {});
      }
    },
    close() {
      this.$router.push({
        name: "donationProject",
      });
    },
    // 撰写内容
    // 详情
    getProjectDetail(id) {
      this.loading = true;
      donationDetail({ id })
        .then((res) => {
          const { data } = res;
          const time = [data.start_time * 1000, data.end_time * 1000];
          console.log(data);
          this.form = {
            name: data.name,
            time: time,
            donation_number: data.donation_number,
            articleContent: {
              image: data.images,
              publicity: data.publicity,
            },
            sponsor_institution: data.sponsor_institution,
            start_institution: data.start_institution,
            target_price: data.target_price,
            questions: data.questions,
            content: data.content,
            quick_donation: data.quick_donation || [],
            large_donation_msg: data.large_donation_msg,
            // 项目公司内容
            donation_publicity: data.donation_publicity,
            // 封面图
            cover: data.cover,
          };
          this.loading = false;
        })
        .catch(() => {});
    },

    // 监听新增公示回调
    addPublicity(form) {
      addPublicity(form).then((res) => {
        // 添加成功将数据插入公示列表
        console.log(res.data);
        this.form.donation_publicity.push(res.data);
        this.$message.success("新增公示成功!");
      });
    },
    // 监听修改公示回调
    editPublicity(form) {
      this.form.donation_publicity.forEach((item) => {
        // 找到修改的id项
        if (item.id === form.id) {
          item = form;
        }
      });
    },
    // 新增项目公示
    addArticleContent() {
      // 清空上一次修改公司的内容
      this.publicityEditContent = {};
      this.isShowPublicity = true;
    },
    // 修改项目公示内容
    editContent(item) {
      // 修改公式的内容
      this.publicityEditContent = item;
      this.isShowPublicity = true;
    },
    // 删除公示内容
    deleteContent({ id }) {
      this.$confirm("是否删除该公示内容？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 拿走对应索引
        let index = this.form.donation_publicity.findIndex(
          (item) => item.id === id
        );
        console.log(index);
        // 删除对应数据
        this.form.donation_publicity.splice(index, 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-project {
  .content {
    .project-card {
      .title {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 16px;
        border-left: 3px #3479ef solid;
      }
      .project-content {
        padding: 20px 20px;

        .publicity {
          width: 100%;
          max-height: 500px;
          overflow: auto;
          margin-top: 10px;
          // background-color: pink;
          .publicity-item {
            padding: 15px 20px;
            border-bottom: 1px solid #d9d9d9;

            .title-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #909399;
              .edit {
                i {
                  color: #000;
                }
                .el-icon-edit:hover {
                  color: blue;
                }
                .el-icon-delete:hover {
                  color: red;
                }
              }
            }
          }
        }
        .large_msg {
          ::v-deep .el-textarea__inner {
            width: 400px;
            height: 250px;
          }
        }
      }
    }
    .img-tips {
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>
