/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 捐款列表数据
export const donationList = data => {
  return api({
    url: '/admin/donation/donation/index',
    method: 'post',
    data
  });
};

// 捐款项目导出
export const donationExport = data => {
  return download({
    url: '/admin/donation/donation/export',
    method: 'post',
    data,
    download: true
  });
};

// 保存
export const donationSave = data => {
  return api({
    url: '/admin/donation/donation/save',
    method: 'post',
    data
  });
};

// 详情
export const donationDetail = data => {
  return api({
    url: '/admin/donation/donation/detail',
    method: 'post',
    data
  });
};

// 软删除
export const softDelete = data => {
  return api({
    url: '/admin/donation/donation/softDelete',
    method: 'post',
    data
  });
};

// 还原
export const putBacks = data => {
  return api({
    url: '/admin/donation/donation/putBack',
    method: 'post',
    data
  });
};


// 删除
export const Pdelete = data => {
  return api({
    url: '/admin/donation/donation/delete',
    method: 'post',
    data
  });
};
// 新增公示
export const addPublicity = data => {
  return api({
    url: '/admin/donation/donation/addPublicity',
    method: 'post',
    data
  });
};