<template>
  <el-dialog title="HTML片段导入" class="import-article dialog-vertical" :visible="value" width="450px" @close="close">
    <el-form size="medium" label-width="80px" label-position="top" @submit.native.prevent>
      <el-form-item label="代码">
        <el-input v-if="value" v-focus style="width: 100%; max-width: 100%; height: unset;" :rows="6"
                  v-model="code" type="textarea" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm" :loading="loading">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { fixHtmlUrl } from "@/modules/common/api";

  export default {
    name: "ImportHtmlCode",
    props: {
      /** v-model */
      value: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        code: "",
        loading: false,
      }
    },
    methods: {
      close() {
        this.code = '';
        this.$emit('input', false)
      },
      confirm() {
        if (this.code.indexOf('https://') !== -1) {
          this.loading = true
          // 链接转本站域名
          fixHtmlUrl({
            html: this.code || btoa(encodeURIComponent(this.code))
          }).then(res => {
            window.tinymce.execCommand(
              "mceReplaceContent",
              false,
              res && res.data || this.code
              // res && res.data ? decodeURIComponent(atob(res.data.replace(/\+/gi, ' '))) : this.code
            );
          }).catch(() => {
            window.tinymce.execCommand(
              "mceReplaceContent",
              false,
              this.code
            );
          }).finally(() => {
            this.loading = false;
            this.close()
          })
        } else {
          window.tinymce.execCommand(
            "mceReplaceContent",
            false,
            this.code
          );
          this.close()
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
