export function tencentfilelink() {
  const icon = 'tencent-file'
  const text = '腾讯文档链接'
  const tooltip = "插入腾讯文档链接"

  return {
    // pluginName: 'tencentfilelink',
    install: (editor, options = {}) => {
      editor.ui.registry.addMenuItem('tencentfilelink', {
        icon,
        text,
        context: 'insert',
        onAction: function () {
          options.onAction && options.onAction()
        }
      })

      editor.ui.registry.addButton("tencentfilelink", {
        icon,
        tooltip,
        // onclick: function () {
        onAction: function () {
          options.onAction && options.onAction()
        },
      });
    }
  }
}