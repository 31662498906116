<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 18:27:50
-->
<template>
  <el-dialog
    title="撰写内容"
    :visible.sync="show"
    width="700px"
    @open="openDialog"
    class="dialog-vertical add-column-dialog"
  >
    <div class="content">
      <el-form
        :model="form"
        ref="ruleForm"
        label-width="100px"
        class="medium-form"
        @submit.native.prevent
      >
        <el-form-item label="文字内容：" prop="publicity">
          <el-input
            type="textarea"
            v-model="form.content"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片：" prop="image">
          <media-wall
            addText="上传图片"
            :width="150"
            :limit="9 - form.images.length"
            :upload-type="['image']"
            :height="96"
            v-model="form.images"
            :draggable="true"
          >
          </media-wall>
          <p class="img-tips">
            <i class="el-icon-info"></i>拖动图片可以对其排序
          </p>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" @click="onConfirm()"
        >发布</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import MediaWall from "../../../common/components/MediaWall";
export default {
  components: { MediaWall },
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    // 是否是修改公示，有内容传入就是修改
    publicityEditContent: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        content: "", //公示内容
        images: [], //公示图片
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.form = {
            content: "", //公示内容
            images: [], //公示图片
          };
        this.$emit("handleOpen", val);
      },
    },
    // 是否是修改
    isEdit() {
      return !!Object.keys(this.$props.publicityEditContent).length;
    },
  },
  methods: {
    // 确认
    onConfirm() {
      if (!this.form.content && !this.form.images.length) {
        this.$message.error("请输入内容");
      } else {
        console.log(this.form);
        if (this.isEdit) {
          // 修改公示
          this.$emit("editPublicity", this.form);
        } else {
          // 新增公示
          this.$emit("addPublicity", this.form);
          
        }
        this.form = {
            content: "", //公示内容
            images: [], //公示图片
          };
        this.show = false;
      }
    },
    // 打开弹窗
    openDialog() {
      console.log(this.form);

      // 有修改数据直接赋值给 form
      if (this.isEdit) {
        this.form = this.$props.publicityEditContent;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-tips {
  width: 194px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999;
  line-height: 12px;
  margin-top: 5px;
}
</style>
