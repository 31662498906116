<template>
  <div class="add-subjects">
    <div class="subject-list" v-show="myValue.length !== 0">
      <draggable v-model="myValue" animation="500" draggable=".draggable">
        <div
          class="single-subject draggable"
          v-for="(subject, index) in myValue"
          :key="index"
        >
          <div class="item">
            <!-- 操作栏 -->
            <div class="subject-btn">
              <img src="../../assets/operation.png" alt="" />
              <i @click="removeLine(index)" class="el-icon-delete"></i>
            </div>
            <div class="content">
              <div class="label">
                <span>机构头像：</span>

                <SingleMediaWall
                  addText=""
                  v-model="myValue[index].avatar"
                  :width="48"
                  :height="48"
                  ratio="20:20"
                  borderRadius="50%"
                >
                </SingleMediaWall>
                <p slot="info" class="tips" style="width: 194px">
                  建议上传尺寸比例为20px * 20px
                </p>
              </div>
              <div class="label">
                <span>机构名称：</span>
                <el-input
                  v-model="myValue[index].name"
                  placeholder="请输入机构名称"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <p class="img-tips">提示：拖拽可以调整排序</p>
    <el-button
      class="subject-add-btn"
      type="primary"
      size="small"
      @click="addLine"
      >+添加</el-button
    >
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "../../../common/components/SingleMediaWall";

export default {
  name: "ProjectInstitution",
  components: { draggable, SingleMediaWall },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      subjects: [],
      myValue: [],
    };
  },
  watch: {
    value(val) {
      this.myValue = val;
    },
  },
  created() {},
  methods: {
    // 移除
    removeLine(i) {
      this.$confirm("是否删除此机构?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.myValue.splice(i, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加
    addLine() {
      this.myValue.push({ avatar: "", name: "" });
      this.$emit("input", this.myValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-subjects {
  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;
  }
  .subject-list {
    width: 500px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f5f7fa;
    .single-subject {
      display: flex;
      justify-content: space-between;
      .item {
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        margin: 5px 0;
        color: #909399;
        .subject-btn {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(244, 244, 245, 1);
          .el-icon-delete {
            cursor: pointer;
          }
        }
        .content {
          margin: 10px;
          .label {
            display: flex;
            margin-bottom: 20px;
            color: #606266;
            span {
              width: 90px;
            }
            .tips {
              width: 194px;
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #999;
              line-height: 12px;
              margin-left: 10px;
            }
            .el-input {
              flex: 1;
            }
          }
        }
      }
      .flex {
        display: flex;
        padding-top: 10px;
        span {
          width: 15px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .subject-del-btn {
      width: 30px;
      text-align: right;
      cursor: pointer;
    }
  }

  .subject-add-btn {
    margin-top: 10px;
  }
}
.el-icon-delete:hover {
  color: red;
}
</style>
