<template>
  <div class="add-subjects">
    <div class="subject-list">
      <div class="single-subject" v-for="(subject, index) in myValue" :key="index">
        <div class="item flex">
          <!-- 机构 -->
          <el-input v-model="myValue[index]" placeholder="请输入内容"></el-input>
          <!-- 移除-->
          <div @click="removeLine(index)" class="subject-del-btn"><i class="el-icon-delete"></i></div>
        </div>
      </div>
    </div>
    <el-button class="subject-add-btn" type="primary" size="small" @click="addLine">+添加</el-button>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array
      }
    },
    data() {
      return {
        loading: true,
        subjects: [],
        myValue: ['']
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
      }
    },
    methods: {
      // 移除
      removeLine(i) {
        this.myValue.splice(i, 1)
      },
      // 添加
      addLine() {
        console.log(this.value)
        this.myValue.push("")
        this.$emit('input', this.myValue)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .add-subjects {
    .subject-list {
      display: flex;
      flex-direction: column;

      .single-subject {
        display: flex;
        justify-content: space-between;

        .item {
          padding: 5px 0;
        }
      }

      .subject-del-btn {
        width: 30px;
        text-align: right;
        cursor: pointer;
      }
    }

    .subject-add-btn {
      margin-top: 10px;
    }
  }
</style>
