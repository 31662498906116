import {api} from "@/base/utils/request";

/**
 * 通用的预览码生成工具
 * @param {string} content 标识字符串
 * @returns {AxiosPromise}
 */
export const generateQRCode = (content) => {
  return api({
    url: '/admin/admin/tool/qrcode',
    method: 'post',
    data: {
      content
    }
  })
}

export const fixHtmlUrl = (data) => {
  return api({
    url: '/admin/admin/tool/fixHtml',
    method: 'post',
    data
  })
}

export const getURLLink = (path, query) => {
  return api({
    url: '/admin/app_design/link/getUrlLink',
    method: 'post',
    data: {
      path, query
    }
  })
}
