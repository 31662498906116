<template>
  <el-dialog
    title="文章导入"
    class="import-article dialog-vertical"
    :visible="value"
    width="450px"
    @close="close"
  >
    <el-form
      class="medium-form"
      v-loading="loading"
      size="small"
      label-width="80px"
      label-position="left"
      @submit.native.prevent
    >
      <el-form-item label="文章链接">
        <el-input
          size="medium"
          v-if="value"
          v-focus
          style="width: 90%"
          v-model="link"
          type="text"
          placeholder="只支持微信公众号文章"
        ></el-input>
        <p class="info">
          <i class="el-icon-info" />目前未支持导入文章中的视频，需手动上传
        </p>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="confirm"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { getWxPublicArticle } from '@/base/api/common-api'

export default {
  name: 'ImportArticle',
  props: {
    value: {
      type: Boolean,
    },
    api: Function,
  },
  data() {
    return {
      link: '',
      loading: false,
    }
  },
  methods: {
    postManager() {
      if (this.api !== undefined) return this.api({ url: this.link })
      return getWxPublicArticle({
        url: this.link,
      })
    },
    confirm() {
      this.loading = true
      this.postManager()
        .then((res) => {
          this.$emit('confirm', res.data)
          this.close()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    close() {
      this.loading = false
      this.link = ''
      this.$emit('input', false)
    },
  },
}
</script>

<style scoped></style>
