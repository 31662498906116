<template>
  <div
    v-loading="!hasInit"
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth, minHeight: height + 'px' }"
  >
    <!--    <textarea :id="tinymceId" class="tinymce-textarea"/>-->
    <vue-tinymce
      v-if="settings.height"
      v-model="myValue"
      :setup="settings.setup"
      :setting="settings"
    />

    <TencentFileLink v-model="openLink"></TencentFileLink>
  </div>
</template>

<script>
// import 'tinymce/tinymce.min'
// 这种引入方式不知道为啥没有图片缩小放大功能
// import '/public/tinymce/tinymce.min'

/* 这个不引入好像没影响，所以我想把文件删了 */
// import '/public/tinymce/skins/ui/oxide/content.min.css';

// import '/public/tinymce/skins/content/default/content.min.css';

/* npm 里自带的样式不太一样，配合局部引入 tinymce 时这个不引入，界面都出不来 */
// import '/public/tinymce/skins/ui/oxide/skin.min.css';

/* 里面有我们自己在 iconfont 找的 svg 图标，所以不用 npm 的 */
// import '/public/tinymce/icons/default'

/* 这个主题好像不要也行 */
// import 'tinymce/themes/silver'

/* 引用 npm 的好还是放在 public 的好？ */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/code'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
// import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/help'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/template'
import 'tinymce/plugins/table'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/save'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/print'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/media'
import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/importcss'
// import 'tinymce/plugins/codesample'
// import 'tinymce/plugins/charmap'

// import '/public/tinymce/plugins/placeholder' // 使用 script 方式就自动支持了
// import '/public/tinymce/plugins/formatpainter' // 第三方格式刷，因为tinymce后面版本是付费插件了
// import '/public/tinymce/plugins/tencentfilelink' // 简单注册一个插件名，避免控制台说找不到

// import '/public/tinymce/langs/zh_CN'


import VueTinymce from '@packy-tang/vue-tinymce/src/vue-tinymce'

/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import plugins from './plugins'
import toolbar from './toolbar'
// import load from "./dynamic-load-script";
import TencentFileLink from '../plugins/tencentfilelink/tencent-file-link'
import { tencentfilelink } from '../plugins/tencentfilelink/install'

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
// const tinymceCDN =
//   "https://cdn.jsdelivr.net/npm/tinymce-all-in-one@4.9.3/tinymce.min.js";

// const tinymceCDN = 'https://cdn.bootcdn.net/ajax/libs/tinymce/4.9.3/tinymce.min.js'

// const tinymceCDN = location.origin + '/tinymce/tinymce.min.js'
// const tinymceCDN = '/tinymce/tinymce.min.js'

/**
   * Your Tiny API Key
   Your API Key is a unique token that links your TinyMCE instances to your account.
   It grants you access to all of our Premium Features during your free trial.
   */
// const key = "1x9ith1h6m3ke3dlhejetuvkllos61xpjcahh5yn68ta47xa"

export default {
  name: 'Tinymce',
  components: {
    TencentFileLink,
    VueTinymce
  },
  props: {
    id: {
      type: String,
      default: function () {
        return (
          'vue-tinymce-' +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + '')
        )
      },
    },
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table tools import help',
    },
    height: {
      type: [Number, String],
      required: false,
      default: 600,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showMenubar: {
      type: Boolean,
      default: true,
    },
    showStatusbar: {
      type: Boolean,
      default: true,
    },
    showArticleImport: {
      type: Boolean,
      default: true,
    },
    showHtmlImport: {
      type: Boolean,
      default: true,
    },
    inline: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      settings: {},

      openLink: false,
    }
  },
  computed: {
    myValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    },
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        // this.$nextTick(() =>
        //   window.tinymce.get(this.tinymceId).setContent(val || "")
        // );
      }
    },
  },
  created() {
    this.$on('change', (content) => {
      // window.tinymce.get(this.tinymceId).setContent(content)
    })

    // 同时 menubar 也得声明
    this.menuObj = {
      file: { title: 'File', items: 'searchreplace print' },
      edit: {
        title: 'Edit',
        items: 'undo redo | cut copy paste pastetext | selectall',
      },
      insert: {
        title: 'Insert',
        items:
          'link tencentfilelink | imageselector videoselector audioselector | hr | anchor | insertdatetime',
      },
      // view: {title: 'View', items: 'visualaid'},
      view: { title: 'View', items: 'preview fullscreen visualblocks' },
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript | formats | removeformat formatpainter',
      },
      table: {
        title: 'Table',
        items: 'inserttable tableprops deletetable | cell row column',
      },
      tools: {
        title: '工具',
        items: 'code',
      },
      help: {
        title: 'Help',
        items: 'help',
      },
    }
    if (this.showArticleImport && this.showHtmlImport) {
      this.menuObj.import = { title: '导入', items: 'articlelink htmlcode' }
    } else if (this.showArticleImport) {
      this.menuObj.import = { title: '导入', items: 'articlelink' }
    } else if (this.showHtmlImport) {
      this.menuObj.import = { title: '导入', items: 'htmlcode' }
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  beforeDestroy() {
    this.$off('change')
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      // load(tinymceCDN, (err) => {
      //   if (err) {
      //     this.$message.error(err.message);
      //     return;
      //   }
      this.initTinymce()
      // });
    },
    initTinymce() {
      const _this = this
      const settings = {
        selector: `#${this.tinymceId}`,

        language: 'zh_CN',
        // language_url : '/tinymce/langs/zh_CN.js',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',

        inline: this.inline,
        readonly: this.readonly,

        plugins: plugins,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',

        formats: {
          p: { block: 'p', classes: 'gray-paragraph', styles: {color: '#666'} },
        },

        /* 不知道为啥没生效 */
        toolbar_mode: 'scrolling',
        toolbar: this.showToolbar
          ? this.toolbar.length > 0
            ? this.toolbar
            : toolbar
          : [],
        // toolbar_persist: true,
        // toolbar_sticky: true,

        // 这个配置没生效
        toolbar_groups: {
          formatting: {
            text: '文字格式',
            tooltip: 'Formatting',
            items: 'bold italic underline | superscript subscript',
          },
          alignment: {
            icon: 'align-left',
            tooltip: 'alignment',
            items: 'alignleft aligncenter alignright alignjustify',
          },
        },

        content_css: '/tinymce/css/mycss.css',

        // quickbars_insert_toolbar: 'imageselector videoselector link tencentfilelink',
        quickbars_insert_toolbar: '', // 空行光标处会有快捷工具栏出现
        quickbars_selection_toolbar:
          'bold italic | link h2 h3 blockquote formatpainter',

        // 配置每个菜单栏的子菜单项（如下是默认配置）
        menu: this.menuObj,
        menubar: this.showMenubar ? this.menubar : '',

        branding: false, // 隐藏富文本编辑器组件的商标消息” Powered by TinyMCE”

        height: this.height,
        min_height: this.height,

        elementpath: false,

        placeholder: '在这里输入文字',

        body_class: 'panel-body ',
        // 设置图片是否支持缩放
        object_resizing: true,

        statusbar: this.showStatusbar,
        end_container_on_empty_block: true,

        // powerpaste 收费的
        // powerpaste_word_import: 'clean',

        // 自带的 paste 插件配置项
        paste_merge_formats: true, // 粘贴合并相似格式，例：<b>abc <b>bold</b> 123</b> 转换为 <b>abc bold 123</b>
        paste_word_valid_elements: "*[*]", // 从Word粘贴过来时，不会对此处配置的内容进行过滤（需要paste_enable_default_filters为true才生效）
        paste_retain_style_properties: "all", // 从Word粘贴过来时，将保留此处配置的样式
        paste_webkit_styles: "all", // 在WebKit中粘贴时，将保留此处配置的样式
        paste_preprocess: function(plugin, args) {  // 从Word粘贴过来时，被插入到编辑器之前的文本
          args.content = args.content.replace(/([+-]?[0-9]*\.?[0-9]+)pt/g, function(match, group0) {  //将pt统一转为px，包括负数值（-10px这种）
            return Math.abs(Math.round(parseInt(group0, 10) * 96 / 72)) +"px";
          })
        },

        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        // 如果单击图像没有显示工具栏，可能你的图片存在跨域问题，
        // 需要参考本文后面的两个参数：imagetools_cors_hosts 或 imagetools_proxy。
        imagetools_cors_hosts: [
          window.serverConfig.VUE_APP_BASEURL.split('://')[1],
        ],
        // imagetools_credentials_hosts: ['www.tiny.cloud', window.serverConfig.VUE_APP_BASEURL.split('://')[1]],
        default_link_target: '_blank',
        /* 该内容将直接注入到iframe内部（head内的style），并不会与内容一起被提交 */
        content_style:
          'img {max-width: 100%; height: auto;} ' +
          'video {min-width:100%; max-width: 100%; height: auto;} ' +
          'section {max-width: 100%; box-sizing: border-box;}' +
          '.mce-object-video {width: 100%;}',
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: (editor) => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          /* 添加自定义工具栏按钮 */
          // editor.addButton("imageselector", {
          editor.ui.registry.addButton('imageselector', {
            icon: 'image',
            tooltip: '插入图片',
            // onclick: function () {
            onAction: function () {
              _this.$emit('choose', {
                type: 'image',
              })
            },
          })
          // editor.ui.registry.addButton("tencentfilelink", {
          //   icon: "tencent-file",
          //   tooltip: "插入腾讯文档链接",
          //   // onclick: function () {
          //   onAction: function () {
          //     _this.openLink = true
          //   },
          // });
          editor.ui.registry.addButton('videoselector', {
            icon: 'embed',
            tooltip: '插入视频',
            onAction: function () {
              _this.$emit('choose', {
                type: 'video',
              })
            },
          })
          editor.ui.registry.addButton('audioselector', {
            icon: 'audio',
            // text: "音频",
            tooltip: '插入音频',
            onAction: function () {
              _this.$emit('choose', {
                type: 'audio',
              })
            },
          })
          if (_this.showArticleImport) {
            editor.ui.registry.addMenuItem('articlelink', {
              text: '文章',
              onAction: function () {
                _this.$emit('choose', {
                  type: 'import',
                })
              },
            })
          }
          if (_this.showHtmlImport) {
            editor.ui.registry.addMenuItem('htmlcode', {
              text: 'HTML片段',
              onAction: function () {
                _this.$emit('choose', {
                  type: 'html',
                })
              },
            })
          }
          // editor.ui.registry.addMenuItem('tencentfilelink', {
          //   icon: 'tencent-file',
          //   text: '腾讯文档链接',
          //   context: 'insert',
          //   onAction: function () {
          //     _this.openLink = true
          //   }
          // })
          editor.ui.registry.addMenuItem('imageselector', {
            icon: 'image',
            text: '图片',
            context: 'insert',
            onAction: function () {
              _this.$emit('choose', {
                type: 'image',
              })
            },
          })
          editor.ui.registry.addMenuItem('videoselector', {
            icon: 'embed',
            text: '视频',
            context: 'insert',
            onAction: function () {
              _this.$emit('choose', {
                type: 'video',
              })
            },
          })
          editor.ui.registry.addMenuItem('audioselector', {
            icon: 'audio',
            text: '音频',
            context: 'insert',
            onAction: function () {
              _this.$emit('choose', {
                type: 'audio',
              })
            },
          })

          tencentfilelink().install(editor, {
            onAction: () => {
              _this.openLink = true
            },
          })
        },
        // imagetools_fetch_image: function(img) {
        //   console.log('fetch', img)
        //   return new tinymce.util.Promise(function (resolve) {
        //     // Fetch the image and return a blob containing the image content
        //     resolve(new Blob(img));
        //   });
        // },
        // it will try to keep these URLs intact
        // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
        // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
        convert_urls: false,
        // word内容粘贴过程中对内容进行修改
        // paste_postprocess: function (editor, fragment) {
        //   // var allDivs = fragment.node.getElementsByTagName("p");
        //   // for (let i = 0; i < allDivs.length; ++i) {
        //   //   /* 此操作会覆盖原有的属性值 */
        //   //   var st = allDivs[i].style;
        //   //   st.lineHeight = '2';
        //   // }
        // },
        extended_valid_elements:
          'mp-common-poi[*],svg[*],foreignObject[*],foreignobject[*],g[*],rect[*],polygon[*],path[*]',

        // importword_handler: function(editor,files,next){
        //   var file_name = files[0].name
        //   if(file_name.substr(file_name.lastIndexOf(".")+1)=='docx'){
        //     editor.notificationManager.open({
        //       text: '正在转换中...',
        //       type: 'info',
        //       closeButton: false,
        //     });
        //     next(files);
        //   }else{
        //     editor.notificationManager.open({
        //       text: '目前仅支持docx文件格式，若为doc111，请将扩展名改为docx',
        //       type: 'warning',
        //     });
        //   }
        //   // next(files);
        // },
        // importword_filter: function(result,insert,message){
        //   // 自定义操作部分
        //   insert(result) //回插函数
        // }
      }
      this.settings = settings
      // window.tinymce.init(settings)
    },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      arr.forEach((v) =>
        window.tinymce
          .get(this.tinymceId)
          .insertContent(`<img class="wscnph" src="${v.url}" >`)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
/*@import '/public/tinymce/skins/ui/oxide/content.min.css';*/
/*@import '/public/tinymce/skins/ui/oxide/skin.min.css';*/

.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
